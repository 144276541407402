<template>
  <div>
    <eden-page-header
      :title="'Customers'"
      :subtitle="'Corporate - Add new customer'"
    />
    <customer-corporate-form action="add" />
  </div>
</template>

<script>
import CustomerCorporateForm from "@/components/Customers/Corporate/CustomerCorporateForm";
import * as actions from "@/store/action-types";

export default {
  name: "CustomerIndividualAdd",
  components: {
    CustomerCorporateForm,
  },
  data() {
    return {};
  },
  created() {
    this.$store.dispatch(actions.GET_LOCATION_AREAS_LIST);
  },
};
</script>

<style scoped lang="scss"></style>
